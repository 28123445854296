<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                        <div class="breadcrumb-left">
                            <h4>
                            Grading Scheme
                            </h4>
                            <div class="breadcrumb-sub-header">
                            <router-link to="/dashboard">Dashboard </router-link>\ Grading Scheme
                            </div>
                        </div>
                        <div class="breadcrumb-right">
                            <div class="card-toolbar">
                            <v-btn
                                v-if="checkIsAccessible('grading-scheme', 'create')"
                                @click="createGradingScheme()"
                                class="btn btn-primary font-weight-bolder font-size-sm">
                                <i class="fa fa-plus"></i>
                                Add Grading Scheme
                            </v-btn>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                            <div class="row">
                                <v-col cols="12" sm="12" md="6">
                                <v-text-field 
                                    label="Search by Title" 
                                    v-model="search.title" 
                                    outlined 
                                    dense
                                    v-on:keyup.enter="searchGradingSchemes()"
                                    @input="search.title = $event !== null ? $event : ''" 
                                    clearable
                                ></v-text-field>
                                </v-col>
                                
                                <v-col class="d-flex" cols="12" sm="12" md="4">
                                <v-select 
                                    :items="search_statuses" 
                                    v-model="search.status" 
                                    label="Status" 
                                    item-text="name" 
                                    item-value="value" 
                                    v-on:keyup.enter="searchGradingSchemes()"
                                    @input="search.status = $event !== null ? $event : ''" 
                                    outlined 
                                    clearable
                                    dense
                                ></v-select>
                                </v-col>
                                
                                <v-col cols="12" sm="12" md="2">
                                <v-btn 
                                    @click="searchGradingSchemes()" 
                                    :loading="loading" 
                                    class="mt-1 btn btn-primary text-white" 
                                >
                                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                                    Search
                                </v-btn>
                                </v-col>
                            </div>
                        </div>
                    
                        <div class="table-responsive">
                        <v-skeleton-loader
                            type="table-thead"
                            v-if="loading"
                        >
                        </v-skeleton-loader>

                        <v-skeleton-loader
                            v-if="loading"
                            type="table-row-divider@25"
                        >
                        </v-skeleton-loader>

                        <table class="table">
                            <thead>
                            <tr class="text-left">
                            <th>Title</th>
                            <th>Country</th>
                            <th>Status</th>
                            <th>Education Level</th>
                            <th class="pr-3 text-center">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            <template>
                            <tr v-for="(item, index) in grading_schemes" :key="index">
                                <td class="px-2">
                                <span class="text-primary font-weight-bolder d-block font-size-lg">
                                    {{ item.title }}
                                </span>
                                </td>
                                <td class="px-2">
                                    <span class="font-weight-bolder text-dark-65 text-primary mb-1 font-size-lg">
                                        {{ item.country_name ? item.country_name : 'N/A' }}
                                    </span>
                                </td>
                                <td class="px-2">
                                    <span 
                                        class="badge"
                                        :class="item.is_active ? 'badge-success' : 'badge-danger'"
                                    >
                                        {{ item.status_text }}
                                    </span>
                                </td>
                                <td class="px-2">
                                    <span class="font-weight-bolder text-dark-65 text-primary mb-1 font-size-lg">
                                        {{ item.education_level_name ? item.education_level_name : 'N/A' }}
                                    </span>
                                </td>
                                <td class="pr-0 text-center">
                                <template>
                                    <b-dropdown
                                        size="sm"
                                        variant="link"
                                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                        no-caret
                                        right
                                        no-flip
                                    >
                                    <template v-slot:button-content>
                                        <i class="ki ki-bold-more-hor"></i>
                                    </template>
                                    <div class="navi navi-hover min-w-md-250px">
                                        <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('grading-scheme', 'edit')">
                                            <a @click="editGradaingScheme(item)" class="navi-link">
                                                <span class="navi-icon">
                                                    <v-icon color="blue darken-2">fas fa-pen</v-icon>
                                                </span>
                                                <span class="navi-text">Edit</span>
                                            </a>
                                        </b-dropdown-text>
                                        <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('grading-scheme', 'delete')">
                                            <a class="navi-link" @click.prevent="deleteGradingScheme(item.id)">
                                                <span class="navi-icon">
                                                    <v-icon color="red darken-2">fas fa-trash</v-icon>
                                                </span>
                                                <span class="navi-text">Delete</span>
                                            </a>
                                        </b-dropdown-text>
                                    </div>
                                    </b-dropdown>
                                </template>
                                </td>
                            </tr>
                            <tr v-if="grading_schemes.length == 0">
                                <td colspan="6" class="text-center">
                                    No items found
                                </td>
                            </tr>
                            </template>
                            </tbody>
                        </table>
                        <b-pagination
                            v-if="grading_schemes.length > 0"
                            class="pull-right mt-7"
                            @input="getGradingSchemes"
                            v-model="page"
                            :total-rows="total"
                            :per-page="perPage"
                            first-number
                            last-number
                            :disabled="loading"
                        ></b-pagination>
                        </div>
                    </div>

                </div>
            </div>
        </div>
      <create-and-update ref="create-and-update" @refresh="getGradingSchemes"></create-and-update>
    </v-app>    
</template>

<script>
import CreateAndUpdate from "@/view/pages/view/academic/grading-scheme/CreateAndUpdate";
import GradingSchemeService from "@/services/grading-scheme/GradingSchemeService";

const gradingScheme = new GradingSchemeService();

export default{
    components: {CreateAndUpdate},
    data(){
      return{
          total: null,
          perPage: null,
          page: null,
          grading_schemes: [],
          loading: true,
          search:{
            name: '',
            status: ''
          },
          search_statuses: [
            {name: 'Active', value: 1},
            {name: 'Inactive', value: 0},
          ],
      }
  },
  methods:{
      searchGradingSchemes(){
        this.getGradingSchemes();
      },
      getGradingSchemes(){
        this.loading = true;
        gradingScheme
        .paginate(this.search, this.page)
        .then(response => {
            this.grading_schemes=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
        })
        .catch((err) => {
        })
        .finally(() => {
            this.loading = false;
        });
      },
      createGradingScheme(){
          this.$refs['create-and-update'].createGradingScheme();
      },
      editGradaingScheme(item) {
        this.$refs['create-and-update'].editGradingScheme(item);
      },
      deleteGradingScheme(gradingSchemeId){
        this.$confirm({
          message: `Are you sure? `,
          button: {
            no: "No",
            yes: "Yes",
          },
          callback: (confirm) => {
            if (confirm) {
              gradingScheme
              .delete(gradingSchemeId)
              .then((response) => {
                this.getGradingSchemes();
                this.$snotify.success("Grading Scheme Deleted Successfully.");
              })
              .catch((err) => {
              });
            }
          },
        });
      },
  },
  mounted(){
    this.getGradingSchemes();
  }
}
</script>
