<template>
    <v-dialog
        v-model="dialog"
        max-width="800"
        scrollable
        persistent
    >
        <v-card>
            <v-toolbar dark>
                <v-card-title class="text-h5">
                <span>{{ title }} Grading Scheme</span>
                <hr>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="closeDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-text-field
                                v-model="grading_scheme.title"
                                :error="$v.grading_scheme.title.$error"
                                outlined
                                dense
                            >
                                <template v-slot:label>
                                Title <span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.grading_scheme.title.$error">This field is required</span>
                            <span class="text-danger" v-if="errors.title" >* {{ errors.title[0] }}</span>
                        </v-col>

                        <v-col cols="12" md="12">
                            <label for>Description <span class="text-danger">*</span> </label>
                            <ckeditor
                                :config="editorConfig"
                                v-model="grading_scheme.description"
                            ></ckeditor>
                            <span class="text-danger" v-if="errors.description" >* {{ errors.description[0] }}</span>
                            <span class="text-danger" v-if="$v.grading_scheme.description.$error">This field is required</span>
                        </v-col>

                        <v-col cols="12" md="4">
                            Status
                            <v-switch
                                    :label="grading_scheme.is_active ? 'Active' : 'Inactive'"
                                    v-model="grading_scheme.is_active"
                            ></v-switch>
                        </v-col>

                        <v-col cols="12" md="4">
                            <v-autocomplete
                                :items="countries"
                                item-text="title"
                                item-value="id"
                                v-model="grading_scheme.country_id"
                                :error="$v.grading_scheme.country_id.$error"
                                outlined
                                dense
                            >
                                <template v-slot:label>
                                Country <span class="text-danger">*</span>
                                </template>
                            </v-autocomplete>
                            <span class="text-danger" v-if="$v.grading_scheme.country_id.$error">This field is required</span>
                            <span class="text-danger" v-if="errors.country_id" >* {{ errors.country_id[0] }}</span>
                        </v-col>

                        <v-col cols="12" md="4">
                            <v-autocomplete
                                :items="educationLevels"
                                item-text="title"
                                item-value="id"
                                v-model="grading_scheme.education_level_id"
                                :error="$v.grading_scheme.education_level_id.$error"
                                outlined
                                dense
                            >
                                <template v-slot:label>
                                Education Level <span class="text-danger">*</span>
                                </template>
                            </v-autocomplete>
                            <span class="text-danger" v-if="$v.grading_scheme.education_level_id.$error">This field is required</span>
                            <span class="text-danger" v-if="errors.education_level_id" >* {{ errors.education_level_id[0] }}</span>
                        </v-col>
                        <!-- type here -->
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="mr-2" text dark
                    medium
                    @click="closeDialog">
                Cancel
                </v-btn>
                <v-btn
                    v-if="checkIsAccessible('grading-scheme', 'create') || checkIsAccessible('grading-scheme', 'edit')"
                    class="btn btn-primary"
                    medium
                    @click="createOrUpdate()"
                    :loading="loading"
                >
                Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import CountryService from "@/services/country/CountryService";
import GradingSchemeService from "@/services/grading-scheme/GradingSchemeService";
import EducationLevelService from "@/services/academic/education-level/EducationLevelService";

const country = new CountryService();
const gradingScheme = new GradingSchemeService();
const educationLevel = new EducationLevelService();

export default {
    name: "CreateAndUpdate",
    validations: {
        grading_scheme: {
            title: { required },
            description: { required },
            country_id: { required },
            education_level_id: { required },
            is_active: {}
        }
    },
    data() {
        return{
        loading: false,
        dialog: false,
        edit: false,
        title: '',
        errors: [],
        grading_scheme: {
            title: '',
            description: '',
            country_id: '',
            education_level_id: '',
            is_active: 0,
        },
        educationLevels: [],
        countries: [],
        editorConfig: {
          versionCheck: false,
          scayt_autoStartup: true,
          allowedContent: true,
          pasteFromWordRemoveFontStyles : true,
          pasteFromWordRemoveStyles : true,
          pasteFromWordNumberedHeadingToList : true,
          toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList','SpellChecker', 'Scayt','PasteFromWord']],
          contentsCss: [
              'http://cdn.ckeditor.com/4.17.1/full-all/contents.css',
              'https://ckeditor.com/docs/ckeditor4/4.17.1/examples/https://ckeditor.com/docs/ckeditor4/4.17.1/examples/assets/css/pastefromgdocs.css'
              ]
          }
        }
    },

    methods:{
    getAllCountries(){
        country
        .getAllCountry()
        .then((response) => {
            this.countries = response.data.data;
        })
        .catch((err) => {

        })
        .finally(() => {

        });
    },
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
    },
    createGradingScheme() {
      this.title = 'Add';
      this.dialog = true;
      this.getEducationLevels();
      this.getAllCountries();
      this.edit = false;
      this.resetForm();
    },
    editGradingScheme(grading_scheme) {
      this.title = 'Edit';
      this.dialog = true;
      this.edit = true;
      this.getAllCountries();
      this.getEducationLevels();
      this.grading_scheme = grading_scheme;
    },
    createOrUpdate(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        this.loading = true;
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {
        gradingScheme
        .create(this.grading_scheme)
        .then(response => {
        this.$snotify.success("Grading Scheme Created successfully");
        this.resetForm();
        this.closeDialog();
        this.$emit('refresh');
        this.loading = false;
        })
        .catch((error) => {
        this.loading = false;
        this.errors = error.errors;
        this.$snotify.error("Oops something went wrong");
        });
    },

    update: function () {
        gradingScheme
        .update(this.grading_scheme.id, this.grading_scheme)
        .then((response) => {
            this.$snotify.success("Grading Scheme Updated successfully");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
            this.loading = false;
        })
        .catch((error) => {
            this.loading = false;
            this.errors = error.errors;
            this.$snotify.error("Oops something went wrong");
        });
    },
    getEducationLevels(){
        educationLevel
        .all()
        .then((response) => {
            this.educationLevels = response.data.educationLevels;
        })
        .catch((err) => {

        })
        .finally(() => {

        });
    },
    resetForm() {
      this.errors = [];
      this.$v.$reset();
      this.grading_scheme = {
        country_id: '',
        description: '',
        education_level_id: '',
        is_active: 0
      };
    },
  },
}
</script>
